import React from 'react';
import SEO from 'components/SEO';
import MainLayout from '../layouts/mainLayout';

export default () => {
  return (
    <MainLayout withTitle>
      <div className="features-wrapper mgn-sm-top-100">
        <div className="container-fluid">
          <div className="teat-bg">
            <div className="section-item def-w-max">
              <div className="row">
                <div className="col-12">
                  <div className="txt-wrap-sect pdd-rt-50">
                    <h2 className="mgn-bot-30">Terms and Conditions</h2>
                    <h3>Terms and Conditions of Use</h3>

                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 1.</span> This website
                      is owned and operated by Animal Instinct Limited, trading
                      as Feed My Furbaby.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 2.</span> These website
                      terms and conditions ('Terms') govern the use and access
                      by any user of the website at www.feedmyfurbaby.co.nz and
                      any other website owned or operated by the Company or its
                      affiliated companies. If you access and use the Website
                      you are deemed to have agreed to these Terms.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 3.</span> In these
                      Terms, reference to 'we' 'us' and 'our' means the Company,
                      its directors, employees, agents and its associated
                      branches.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 4.</span> Reference to
                      'you' 'your' and 'yours' shall mean any user who is
                      accessing the site at any time.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 5.</span> Reference to
                      'members' means members, users, subscribers or customers
                      of the Company or Feed My Furbaby. Members are bound by
                      these Terms and acknowledge that this includes the
                      membership benefits, contact levels and other details
                      selected by them in the registration process.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 6.</span> The Website is
                      also governed by our privacy policy and this policy forms
                      part of the Terms.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 7.</span> If you wish to
                      register as a member/subscriber you will be required to
                      complete the registration form.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 8.</span> By completing
                      the registration form you are agreeing to these Terms.
                    </p>
                    <h3>Purpose</h3>
                    <p>
                      <span className="text-semi-bold"> 9.</span> The purpose of
                      the Website is to promote our products and services and
                      facilitate the online sale of our products and services.
                    </p>
                    <h3>
                      Ownership of intellectual property and restrictions on use
                      of website material
                    </h3>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 10.</span> With the
                      exception of any third party trademarks or logos provided
                      to us for inclusion on the Website, the Company owns or
                      has licensed all intellectual property rights in the text,
                      graphics on the Website, packaging and its overall design.
                      You must not use, copy, modify, reverse engineer,
                      transmit, store, download, publish or distribute the
                      material on the Website or create any other material using
                      material on the Website without obtaining the prior
                      written consent of the Company. Trade marks (whether
                      registered or unregistered) and logos displayed on the
                      Website must not be used or modified in any way without
                      obtaining the prior written consent of the Company and/or
                      the owner of the trade mark or logo as the case may be.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 11.</span> The
                      information contained or obtained in the use of the
                      Website including products, technology and processes may
                      be the subject of other intellectual property rights owned
                      by the Company or by third parties. No licence is granted
                      in respect of those intellectual property rights other
                      than as set out in these Terms. Your use of the Website
                      must not in any way infringe the intellectual property or
                      other rights of any person.
                    </p>

                    <h3>Exclusions and limitations of liability</h3>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 12.</span> To the extent
                      permitted by law, we are not liable to you or anyone else
                      for any loss, damage or expense, however caused, which
                      have been directly or indirectly suffered in connection
                      with the use of the Website and we exclude liability
                      (whether that liability arises in contract, tort
                      (including negligence) or statute) for any direct,
                      special, indirect or consequential loss or damage
                      (including without limitation loss of revenue and loss of
                      or damage to data) suffered or incurred by you or any
                      person in connection with your access to or use of the
                      Website.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 13.</span> Without
                      limiting the generality of the foregoing and to the extent
                      permitted by law, we: <br />
                      (a) make no warranty (whether express, implied or
                      statutory) as to the completeness, accuracy, reliability
                      or fitness for purpose of any material on the Website;{' '}
                      <br />
                      (b) are not liable to you or to anyone else for any errors
                      in the information on the Website including in any third
                      party content provided to us for inclusion on the Website;
                      <br /> (c) are not liable for any failure or
                      unavailability of the Website for any time period; and{' '}
                      <br />
                      (d) reserve the right to modify or withdraw any particular
                      service or product referred to in this Website or any
                      Company database, material, service and/or system. The
                      Company reserves the right to terminate access to this
                      Website and/or to take any other action it believes
                      necessary to comply with the law or to protect its rights,
                      members and/or customers. Any access or attempt to access
                      or use this Website for any unauthorized or illegal
                      purpose is strictly prohibited.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 14.</span> The Company
                      advises you to exercise discretion while browsing this
                      Website and the Internet. In addition, Hyperlinks on this
                      Website may direct you to sites containing information
                      that some people may find offensive or inappropriate. Such
                      linked Website may not be under the control of the Company
                      and the Company does not make any representations
                      concerning any such Website which you may access via a
                      Hyperlink from this Website, and accordingly the Company
                      shall not be responsible for the accuracy, copyright
                      compliance, legality, legitimacy or decency of material
                      contained in any such Website which may be accessible via
                      a Hyperlink to or from this Website or for the Hyperlink
                      itself. The Company is providing these Hyperlinks to you
                      only as a convenience and the inclusion of any Hyperlink
                      on this Website is not and should not imply any
                      endorsement by the Company of such linked Website.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 15.</span> Some parts of
                      this Website are provided without charge as a convenience
                      to visitors to be used for the purposes of information,
                      education and communication. While all information advice
                      and/or material(s) contained in this Website have been
                      prepared and compiled from sources believed to be
                      reliable, the Company makes no guarantee(s) and shall not
                      be responsible nor liability for to the correctness,
                      reliability, accuracy, sufficiency, suitability (for a
                      particular purpose or purposes) and/or completeness or
                      otherwise of such information, advice and/or material and
                      shall not be held responsible nor liable in relation
                      thereto. The Company does not represent nor endorse the
                      correctness, reliability, accuracy, sufficiency,
                      suitability (for a particular purpose or purposes) and/or
                      completeness or otherwise of any of the information,
                      content or advertisements contained on, distributed
                      through, or linked, downloaded or accessed from this
                      Website, nor the quality of any product(s) supplied by
                      third parties, information or other material(s) or
                      service(s) displayed, obtained or purchased by you as a
                      result of your use of this Website or any of the Company’s
                      services, or system(s). In accessing and/or using this
                      Website you acknowledge that you do so at your own risk.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 16.</span> You assume
                      the responsibility to take adequate precautions against
                      damages to your systems and/or operations which could be
                      caused by defects or deficiencies in this Website, any the
                      Company information, service(s), system(s), database(s)
                      and/or material(s), or part(s) thereof. You also
                      acknowledge that electronic communications and databases
                      are subject to errors, tampering and break-ins and that
                      while the Company will implement reasonable security
                      precautions to attempt to prevent such occurrences, the
                      Company does not guarantee that such events will not take
                      place. You are also solely responsible for ensuring that
                      all posting(s) made by or for you to this Website does not
                      contain any virus or mistake(s) or other computer software
                      code or routine designed to disable, erase, impair or
                      otherwise damage the site or any system, software or data
                      of the Company or any other user of the the Company
                      Website/software and you hereby agree to indemnify, defend
                      and save the Company and its members, employees,
                      resellers, agents, representatives, licensors and
                      suppliers harmless from all and any liability, claim, cost
                      or damage arising out of any claim or suit by any such
                      user caused by such virus or other computer software code
                      or routine.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 17.</span> Neither the
                      Company nor its members, employees, resellers, agents,
                      representatives, licensors or suppliers shall in any event
                      be liable to you or to any third party for any loss of
                      profits, revenues, business opportunities or business
                      advantages whatsoever, nor for any direct, indirect,
                      special, consequential, indirect or incidental losses,
                      damages or expenses directly or indirectly incurred
                      relating to the use or misuse of this Website, or with
                      respect to any other hyperlinked Website, or any Company
                      information, content or other material or software used
                      therewith, the use or failure, non-compliance or limited
                      availability of any information, content, or service
                      provided by the Company through this Website, any
                      information provided by the Company or any obligation
                      under or subject matter of this Website, whether such
                      claim is based upon breach of contract, breach of
                      warranty, negligence, gross negligence, strict liability,
                      in tort and/or any other cause of action whatsoever,
                      whether or not the Company is informed in advance of the
                      possibility of such loss(es) or damage(s)
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 18.</span> Without
                      limiting the preceding clauses and to the extent that our
                      liability is not excluded under those clauses or any other
                      clause in these Terms, the maximum amount for which we are
                      liable to you under or in connection with these Terms
                      (whether that liability arises in contract, tort
                      (including negligence) or Statute) is $100.
                    </p>

                    <h3>Payment terms </h3>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 19.</span> Our website
                      accepts payments using the following credit cards:
                      <ul>
                        <li>Visa</li>
                        <li>Mastercard</li>
                        <li>American Express</li>
                        <li>Diners and Discover</li>
                      </ul>
                      If you are using the online credit card payment facilities
                      on this site then it is your responsibility to ensure that
                      the transaction, credit card information, and all other
                      details you provide in relation to your payments made
                      through the Website are correct. We do not accept any
                      liability for transactions which are incorrect as a result
                      of inaccurate data or information provided by you.
                    </p>
                    <h3>Credit Card Transaction Security</h3>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 20.</span> All credit
                      card transactions for the purchase of goods from this
                      website are processed via Stripe, an externally hosted
                      secure payments page/system dedicated to this purpose.
                      Your card details will be entered into the Stripe system
                      only. The Company does not receive and cannot retrieve the
                      complete number sequence of your credit card from the
                      transaction record. These details are only entered for
                      processing the transaction on Stripe secure SSL servers.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 21.</span> Stripe is
                      certified and compliant to the Payment Card Industry Data
                      Security Standards (PCI DSS), as a level 1 processor; this
                      includes quarterly scans of its systems and annual onsite
                      audits by a Qualified Security Assessor (QSA). Stripe PCI
                      compliance validation can be found in VISA's Global List
                      of PCI DSS Validated Service Providers.
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 22.</span> Stripe is a
                      best practice system used to facilitate electronic
                      payments, from multiple access points i.e. EFTPOS,
                      Billing, IVR, CRM, Vending (GPRS), CRM (Mail Order /
                      Telephone Order) and Web, seamlessly to a chosen acquirer
                      in real time. Refund policy
                    </p>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 23.</span> All refunds
                      will be made back to the purchaser's credit card. If you
                      have any queries regarding a payment please contact us.
                    </p>
                    <h3>Amendments</h3>
                    <p className="para-txt mgn-bot-20">
                      <span className="text-semi-bold"> 24.</span> We may amend
                      these Terms from time to time, without prior notice. We
                      will notify you of any changes by posting the amended
                      Terms on the Website and the changes will be effective
                      from the date and time they are posted.
                    </p>
                    <h3>Governing Law</h3>
                    <p className="para-txt mgn-bot-50">
                      <span className="text-semi-bold"> 25.</span> This Website
                      and these Terms are governed by the Laws of New Zealand
                      and users submit to the non-exclusive jurisdiction of the
                      Courts of New Zealand.
                    </p>
                    <h2>Privacy Policy</h2>
                    <p className="para-txt mgn-bot-20">
                      The Company is committed to ensuring that your privacy is
                      protected. This Privacy Policy governs the collection, use
                      and disclosure of your personal data by us and has been
                      prepared in accordance with our obligations and your
                      rights set out in the New Zealand Privacy Act 1993. By
                      accepting this Privacy Policy, you acknowledge that the
                      Privacy Legislation in other jurisdictions does not apply.
                      We reserve the right to modify or amend this Privacy
                      Policy at any time and for any reason. By using this
                      Website you consent to our use of your personal data in
                      accordance with this Privacy Policy (as amended from time
                      to time). <br />
                    </p>
                    <p className="para-txt mgn-bot-20">
                      This Privacy Policy forms part of the Terms governing your
                      use of the Website. It explains:
                      <ul>
                        <li> What kind of personal data we collect</li>
                        <li> Where we collect personal data from</li>
                        <li> How we use personal data</li>
                        <li> How we store personal data</li>
                        <li> How you can access/amend your personal data</li>
                        <li> How long we hold personal data</li>
                        <li> Who you can contact for more information</li>
                      </ul>
                    </p>

                    <h3>What kind of personal data we collect</h3>
                    <p>
                      We collect only the personal data you give us (including
                      your name, email address, and contact telephone numbers).
                      We may also keep a record of any information that you
                      acquire from us.
                    </p>
                    <h3>Where we collect personal data from</h3>
                    <p>
                      We collect personal data from the following sources:
                      <br />
                      - By you inquiring about information on us, our services
                      or anything on this Website.
                      <br />- Directly from you when you purchase from our
                      Website or when you complete an online contact form or
                      registration.
                    </p>

                    <h3>Cookies</h3>
                    <p>
                      We may use "cookies" to provide you with access to
                      tailored information and services on this Website, and to
                      serve you better when you return to it. A cookie is a
                      small data file that the Website sends to your browser,
                      which may then store it on your system for later retrieval
                      by the Website. Cookies track your movements through
                      different websites; they do not record any other personal
                      information about you. Cookies are widely used on websites
                      to help with navigation and to help personalise your
                      experience when you visit a website.
                    </p>
                    <p>
                      We may use non-personalised statistics about the use of
                      this Website to continually improve it and improve
                      customer service. These statistics do not comprise
                      personal information.
                    </p>

                    <h3>How we use personal data</h3>
                    <p>
                      Your personal information will never be given to any third
                      party. We use your personal data for the following
                      purposes:
                      <br />
                      - Replying to your queries <br />- Processing your
                      registration as an email only subscriber, or processing
                      your membership application <br />- Providing you with
                      information about us and our services <br />
                      - Making your information available to other users of the
                      Website and other members in accordance with the contact
                      levels you have chosen <br />- Providing you with services
                      in relation to this Website <br />- Complying with
                      relevant legislation and regulations <br />- Any specific
                      purpose which we notify you of at the time personal data
                      is collected
                    </p>

                    <h3>How we store personal data</h3>
                    <p>
                      We store personal data electronically. The data is
                      protected and secure using generally accepted standards of
                      security.
                    </p>

                    <h3>How you can access/amend your personal data</h3>
                    <p>
                      You may access your personal data we hold by contacting
                      us. We will provide you with a copy of the personal data
                      we keep about you. You may request that the personal data
                      we hold about you be corrected or deleted at any time.
                    </p>

                    <h3>How long we hold personal data</h3>
                    <p>
                      We'll keep your personal data only for as long as
                      necessary to achieve the purpose we collected it for.
                    </p>
                    <h3>Who you can contact for more information</h3>
                    <p>
                      If you have any queries about this Privacy Policy or
                      personal data we've collected please contact us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
